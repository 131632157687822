// main base colors for site
$main-background: black;
$main-foreground: white;
$button-background: darken(darkblue, 12%);
$button-foreground: white;
$lower-left-background: darkblue;
$lower-right-background: black;
$event-color: darken(blue, 20%);

$background-opacity: 0.7;

h1 {
  color: gold;
  margin-bottom: 0.5em;
  font-weight: bolder;
  font-size: 2rem; /* Adjusted for smaller size */
}

h2 {
  color: gold;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1.75rem; /* Adjusted for smaller size */
}

h3 {
  color: gold;
  margin-bottom: 0.25em;
  font-size: 1.5rem; /* You can adjust this as needed */
}

h4 {
  color: gold;
  margin-bottom: 0.25em;
  font-size: 1.25rem; /* Adjust as needed */
}

b {
  color: gold
}

// style for links
a {
  color: darken(yellow, 10%);
}

iframe {
  border: none;
  display: block
}

// default colors for text and background
@mixin default-colors {
  background-color: $main-background;
  color: $main-foreground;
}

// style for all buttons
@mixin button-style {
  background-color: $button-background;
  color: $button-foreground;
  font-size: 1em
}

@mixin border-style {
  padding-top: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
}


// style for active buttons
@mixin button-style-active {
  background-color: lighten($button-background, 20%);
  color: lighten($button-foreground, 20%);
  font-size: 1em
}


html, body, .container-fluid, .left-part, .main-part, .bottom-part {
  height: 120%
}

// main body style
body {
  @include default-colors;
  @include border-style
}

.event-link {
  background-color: $event-color;
}

// top part style
.top-part {
  @include border-style
}

#banner {
  background-image: url('/main/images/banner.jpg');
  background-repeat: no-repeat
}

// left part style
.bottom-part {

  background: linear-gradient(rgba(0, 0, 0, $background-opacity), rgba(0, 0, 0, $background-opacity)),
  url('/main/images/Background_Oststernwarte_1600.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @include border-style
}

.card {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 1%;
  margin-right: 1%
}

.card-header {
  font-size: 1.2em
}

// make sure that styles are used in all button states
@each $button in btn, btn-default, dropdown-item, "dropdown-toggle.btn-default", "open > dropdown-toggle.btn-default" {
  .#{$button} {
    @include button-style;

    &:hover, &:focus, &:active {
      @include button-style-active
    }
  }
}

.selected {
  @include button-style-active
}

// and for the dropdown menu
.dropdown-menu {
  @include button-style;

  li {
    a {
      @include button-style
    }

    a:hover, a:focus, a:active {
      @include button-style-active
    }
  }
}

// style for image boxes
.image-with-caption {
  img {
    margin: auto;
  }

  p {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px
  }
}

// styles for side menu
.side-menu {
  font-size: 0.8em
}

// style for gallery overview
.gallery-item {
  border: solid 1px;
  border-radius: 6px;
  border-color: darken(yellow, 30%);
  padding: 5px;
  margin: 5px
}

.gallery-button {
  font-weight: bold;
  font-size: 1.5em;
  border: solid 1px;
  border-radius: 3px;
  background-color: darken(darkgrey, 30%);
  margin: 2px
}

.modal {
  border: solid 2px;
  border-radius: 10px;
  border-color: darken(yellow, 30%);
  padding: 5px
}

// style for gallery zoom
.modal-header, .modal-content, .modal-footer {
  background-color: darken(darkblue, 20%);
  color: white;

  button {
    opacity: 1.0
  }
}

// style for events
.event {
  text-align: center;
  background-color: $event-color;
  font-size: 1.0em
}

.warning {
  background-color: red;
  text-align: center;
  color: yellow;
  font-size: 1.2em
}

.black-background {
  background-color: black
}

.bg-event {
  background-color: $event-color;
}

.bg-ok {
  background-color: darken(green, 5%);

}

.bg-unclear {
  a {
    color: darkred;
  }

  background-color: darken(yellow, 20%);
}

.embed-full {
  display: block;
  width: 70vw;
  height: 70vw;
}

// style for author text at end of report

.author {
  text-align: center;
  font-style: italic;
}

.bg-success {
  a {
    color: yellow
  }
}

.cancelled {
  color: red;
  text-decoration: line-through;
}
